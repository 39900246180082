import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import axios from 'axios'

Vue.config.productionTip = false

//to be able to display snackbar alerts all over the app
export const snackBarBus = new Vue()

//Vue.config.productionTip = false
axios.defaults.baseURL = 'https://folketshus-api.webomaten.se/rest'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8'
new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
