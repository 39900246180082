<template>
  <v-app>
    <v-main>
      <div v-if="!userHasInteracted" class="flex-center">
        <div>
          <h1>Folkets Hus Bong</h1>
          <v-btn
            @click="start()"
            color="secondary"
            x-large
          >
            Öppna sidan
          </v-btn>
        </div>
      </div>
      <div v-else-if="!viewDataIsLoaded" class="flex-center">
        <div>
          
        </div>
      </div>
      <template v-else>
        <v-banner
          single-line
          v-if="barIsOpen"
        >
          <v-icon
            slot="icon"
            color="success"
            size="48"
          >
            mdi-check-circle
          </v-icon>
          Baren är öppen - updaterad {{ fetchedAt }}
          <template v-slot:actions>
            <v-btn
              color="primary"
              @click="closeBar()"
            >
              Stäng baren
            </v-btn>
          </template>
        </v-banner>
        <v-banner
          single-line
          v-else
        >
          <v-icon
            slot="icon"
            color="error"
            size="48"
          >
            mdi-alert-octagon
          </v-icon>
          Baren är stängd - updaterad {{ fetchedAt }}
          <template v-slot:actions>
            <v-btn
              color="primary"
              @click="openBar()"
            >
              Öppna baren
            </v-btn>
          </template>
        </v-banner>
        <!--
        <v-app-bar
          fixed
          elevate-on-scroll
          dark
          dense
          color="primary"
        >
          <v-toolbar-title>Folkets Hus Bong <span class="title-byline">updaterad {{ this.fetchedAt }}</span></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          </v-toolbar-items>
        </v-app-bar>
        -->
        <v-container>
          <v-row class="pt-2">
            <v-col class="pt-4">
              <div class="list-holder" v-for="list of lists" :key="list.label">
                <h1 v-if="list.orders.length">
                  {{ list.name }}
                  <template v-if="list.orders.length">
                    - {{ list.orders.length }} st
                  </template>
                </h1>
                <div class="no-orders-msg" v-if="list.orders.length == 0">
                  {{ list.noOrdersMessage }}
                </div>
                <div v-else class="order-holder" :class="'order-holder-' + list.label">
                  <div v-for="order of list.orders" :key="order.id" :class="{ 'new-order': order.isNew }">
                    <div>
                      <div>
                        {{ order.id }}
                      </div>
                      <div>
                        <div>{{ order.time }}</div>
                        <div v-if="order.date.length">{{ order.date }}</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div v-for="(item, index) of order.items" :key="index">
                          <div>{{ item.quantity }} st</div>
                          <div>{{ item.name }}</div>
                        </div>
                      </div>
                      <v-btn
                        v-if="list.label == 'unprocessed'"
                        color="secondary"
                        @click="deliverOrder(order)"
                        large
                      >
                        {{ order.doneButtonText }}
                      </v-btn>
                    </div>
                    <div>
                      <div>
                        <div v-for="(comment, index) of order.comments" :key="index">
                          {{ comment }}
                        </div>
                      </div>
                      <div>{{ order.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <!--snack-bar-->
      <app-snack-bar></app-snack-bar>
    </v-main>
    <!--loader-->
    <v-overlay
      :value="loading"
      opacity="0.8"
      z-index="1001"
    >
      <v-progress-circular
        :size="60"
        color="secondary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import axios from 'axios'
import SnackBar from './components/SnackBar'
import { snackBarBus } from './main'
import { generalMixin } from './mixins/general'
import { urlMixin } from './mixins/url'

export default {
  name: 'App',
  components: {
    'app-snack-bar': SnackBar,
  },
  mixins: [generalMixin, urlMixin],
  data: () => ({
    loading: false,
    viewDataIsLoaded: false,
    showedOrderIds: [],
    lists: [],
    anyNewOrder: false,
    getDataTimeout: null,
    notificationSound: null,
    userHasInteracted: false,
    fetchedAt: '',
    barIsOpen: null,
  }),
  computed: {
  },
  methods: {
    pollGetData() {
      this.getDataTimeout = setTimeout(() => {
        this.getData()
      }, 10*1000)
    },
    getData(act) {
      console.log('getData')
      // if (this.loading) {
      //   return
      // }
      if (act) {
        this.loading = true
      }
      let relUrl = '/bong/home/'
      const url = this.url_composeApiUrl(relUrl)
      const postData = {
        showedOrderIds: this.showedOrderIds
      }
      if (act) {
        postData.act = act
      }
      const config = {}
      console.log('post', url)
      axios.post(url, postData, config)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onData(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
          this.pollGetData();
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
          this.pollGetData()
        })
    },
    onData(data) {
      this.viewDataIsLoaded = true
      this.lists = data.lists
      this.showedOrderIds = data.showedOrderIds
      this.anyNewOrder = data.anyNewOrder
      this.fetchedAt = data.fetchedAt
      this.barIsOpen = data.barIsOpen
      if (this.anyNewOrder) {
        this.notificationSound.play()
      }
    },
    clearGetDataTimeout() { 
      clearTimeout(this.getDataTimeout)
    },
    deliverOrder(order) {
      //clear get data timeout
      this.clearGetDataTimeout()
      //do act
      const act = {
        label: "deliver",
        id: order.id
      }
      this.getData(act)
    },
    openBar() {
      //clear get data timeout
      this.clearGetDataTimeout()
      //do act
      const act = {
        label: "open"
      }
      this.getData(act)
    },
    closeBar() {
      //clear get data timeout
      this.clearGetDataTimeout();
      //do act
      const act = {
        label: "close"
      }
      this.getData(act)
    },
    start() {
      //we have to do this in order to be able to play a sound, otherwise chrome for example will say: "Uncaught (in promise) DOMException: play() failed because the user didn't interact with the document first"
      this.userHasInteracted = true
      this.getData()
      this.loading = true
    }
  },
  mounted() {
    this.notificationSound = new Audio(require('@/assets/mixkit-happy-bells-notification-937.mp3'))
  },
  beforeDestroy() {
    console.log('beforeDestroy')
    this.clearGetDataTimeout()
  }
}
</script>
<style scoped>
  * {
    line-height: 1em;
  }
  h1 {
    margin: 0 0 20px;
  }
  span.title-byline {
    display: inline-block;
    margin: 0 0 0 10px;
    font-size: 15px;
  }
  div.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95%;
  }
  div.flex-center > div {
    text-align:center;
  }
  div.list-holder {
    margin:0 0 40px;
  }
  div.no-orders-msg {
    font-size: 20px;
    color:rgb(38, 107, 59);
  }
  div.new-order {
    background-color:rgb(255, 208, 226) !important;
  }
  /* order block */
  div.order-holder > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 10px 10px;
    margin: 0 0 20px;
  }
  div.order-holder-unprocessed > div {
    background-color:rgb(255, 241, 208);
  }
  div.order-holder-processed > div {
    background-color:rgb(217, 255, 208);
  }
  /* order block left col */
  div.order-holder > div > div:nth-of-type(1) {
    flex:0 0 120px;
  }
  /* order block middle col */
  div.order-holder > div > div:nth-of-type(2) {
    flex:0 0 calc(100% - 400px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  /* order block right col */
  div.order-holder > div > div:nth-of-type(3) {
    flex:0 0 160px;
  }
  /* order number */
  div.order-holder > div > div:nth-of-type(1) > div:nth-of-type(1) {
    font-size: 60px;
    text-align: center;
    padding:0 0 0;
    font-weight: 600;
    position: relative;
    top:-4px;
  }
  /* order date/time holder */
  div.order-holder > div > div:nth-of-type(1) > div:nth-of-type(2) {
    padding: 0;
  }
  /* order time */
  div.order-holder > div > div:nth-of-type(1) > div:nth-of-type(2) > div:nth-of-type(1) {
    font-size: 40px;
    text-align: center;
    font-weight: 400;
  }
  /* order date */
  div.order-holder > div > div:nth-of-type(1) > div:nth-of-type(2) > div:nth-of-type(2) {
    font-size: 20px;
    text-align: center;
    margin: 5px 0 0;
    font-weight: 400;
  }
  div.order-holder > div > div:nth-of-type(2) > div:nth-of-type(1) {
    display: table;
    margin:0 0 20px;
  }
  /* order items - rows */
  div.order-holder > div > div:nth-of-type(2) > div:nth-of-type(1) > div {
    display: table-row;
  }
  /* order items - cols */
  div.order-holder > div > div:nth-of-type(2) > div:nth-of-type(1) > div > div {
    display: table-cell;
    vertical-align: top;
    padding: 4px 4px;
    font-size: 18px;
  }
  /* order items - quantity col */
  div.order-holder > div > div:nth-of-type(2) > div:nth-of-type(1) > div > div:nth-of-type(1) {
    width: 50px;
  }
  /* order comments */
  div.order-holder > div > div:nth-of-type(3) > div:nth-of-type(1) > div {
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 1.2em;
    margin:0 0 5px;
  }
  /* order comments - seating */
  div.order-holder > div > div:nth-of-type(3) > div:nth-of-type(1) > div:nth-of-type(1) {
    font-size: 18px;
    font-weight: 600 !important;
  }
  /* order name */
  div.order-holder > div > div:nth-of-type(3) > div:nth-of-type(2) {
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    margin:10px 0 0;
  }
</style>